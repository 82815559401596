body {
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    pointer-events: all !important;
}

p {
    margin: 0;
}

body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
    box-sizing: border-box;
    font-size: 62.5%;
}

#root {
    height: 100%;
}

/* Tailwind CSS imports */
@tailwind base;
@tailwind components;
@tailwind utilities;
